<template>
  <ASmoothReflow v-if="offersMainProducts.length">
    <OSection
        :title-heading="$t('Special offers VARUS')"
        key="special-offers"
        is-centered
        is-not-mobile-padding
        class="home--special-offers-products"
    >
      <template #link>
        <SfLink
            :link="localizedRoute(formatRegionUrl('/rasprodazha'))"
            class="sf-link--primary all-products-link"
        >
          {{ $t('All goods') }}
        </SfLink>
      </template>
      <MProductCarousel
          v-if="offersMainProducts.length"
          list-id="home_special_offers"
          :list-name="$t('Special offers VARUS')"
          :products="offersMainProducts"
          :key="offersMainProducts.length"
          :es-url-param="esUrlParamProductOffersMainWidget"
          arrow-color="white"
          :show-qty-permanently="true"
          :lazy="true"
      />
    </OSection>
  </ASmoothReflow>
</template>

<script>
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow';
import intersect from 'theme/directives/intersect';
import MProductCarousel from 'theme/components/molecules/m-product-carousel.vue';
import { SfLink } from '@storefront-ui/vue';
import OSection from 'theme/components/organisms/o-section.vue';
import { mapActions, mapState } from 'vuex';
import { getAndStoreRelatedProducts, getEsputnikUrl } from 'theme/helpers';
import config from 'config';
import { formatRegionUrl } from '$modules/shipping/utils/region-url';
export default {
  name: 'OHomeSpecialOffers',
  components: {
    OSection,
    SfLink,
    MProductCarousel,
    ASmoothReflow
  },
  data () {
    return {
      esUrlParamProductOffersMainWidget: ''
    }
  },
  props: {
    offersMainProducts: {
      type: Array,
      default: () => []
    }
  },
  directives: { intersect },
  computed: {
    ...mapState({
      currentShipping: state => state['shipping-module'].current,
      loadedShipping: state => state['shipping-module'].loaded.shipping
    }),
    shippingChangeTrigger () {
      return {
        shopId: this.currentShipping?.shop?.id,
        method: this.currentShipping?.method,
        newPostRef: this.currentShipping?.npShop?.ref,
        loaded: this.loadedShipping
      }
    },
    productOffersMainWidgetId () {
      return config.esputnik?.widgetIds?.productOffersMain;
    }
  },
  methods: {
    formatRegionUrl,
    ...mapActions({
      loadSampleProducts: 'homepage/loadSampleProducts'
    }),
    getProductOffersMain () {
      getAndStoreRelatedProducts(
        this.productOffersMainWidgetId,
        'productOffersMain',
        config?.entities?.product?.carouselSize,
        true
      )
    }
  },
  watch: {
    shippingChangeTrigger: {
      handler: async function (newValue, oldValue) {
        if (
          !newValue?.loaded || (
            newValue?.shopId && (
              newValue?.shopId === oldValue?.shopId &&
              newValue?.method === oldValue?.method &&
              newValue?.newPostRef === oldValue?.newPostRef &&
              newValue?.loaded === oldValue?.loaded
            )
          )
        ) return

        // await this.loadSampleProducts('specialOffers')
        this.getProductOffersMain()
      }
    }
  },
  mounted () {
    window.addEventListener('productOffersMainLoaded', this.getProductOffersMain)
    this.esUrlParamProductOffersMainWidget = getEsputnikUrl('#' + this.productOffersMainWidgetId + ' .recommend-product-id')
  },
  beforeDestroy () {
    window.removeEventListener('productOffersMainLoaded', this.getProductOffersMain)
  }
};
</script>
